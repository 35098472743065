import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const PostJobs = () => {
    return (
        <div className="overflow-hidden">
            <Navbar auth={true} type="blue" />

            <section>
                <div className="container">
                    <div className="text-center">
                        <div className="wrapper__label-premium d-inline-block medium font__size--14 mb-4">
                            Access Premium
                        </div>
                        <h4 className='medium font__size--40 text__40-1024 text__40-md text__40-mm'>It’s easy to get started</h4>
                        <p className='normal font__size--16 text__16-1024 color__gray-2'>Looking to post a job opening? Choose the option that <br /> best fits for your needs.</p>
                    </div>
                    <div className="mt-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-8">
                                <div className="row">
                                    <div className="mb-4 mb-lg-0 col-md-6">
                                        <div className="wrapper__card-price">
                                            <p className='medium font__size--20 text__20-1024'>Free Trial</p>
                                            <h5 className='medium font__size--40 text__40-1024 text__40-md text__40-mm mb-0'>$0<span className='medium font__size--16 text__16-1024 color__gray-2'>/Month</span></h5>
                                            <hr className='my-4' />

                                            <ul className='list__feature mb-4'>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>1 Job credit</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>Featured for 3 day</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>FeatPublished for 1 month</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>3 day approval</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/check (2).png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>Verfied Company</span>
                                                    </div>
                                                </li>
                                            </ul>

                                            <NavLink to="/post-jobs/form" className='medium font__size--16 text__16-1024 btn btn__outlined--blue no__opacity color__blue shadow rounded__50 w-100'>Puchase Credit</NavLink>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="wrapper__card-price">
                                            <p className='medium font__size--20 text__20-1024'>Free Trial</p>
                                            <h5 className='medium font__size--40 text__40-1024 text__40-md text__40-mm mb-0'>$54<span className='medium font__size--16 text__16-1024 color__gray-2'>/Month</span></h5>
                                            <hr className='my-4' />

                                            <ul className='list__feature mb-4'>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>6 Job credit</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>Featured for 1 week</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>FeatPublished for 3 month</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>1 day approval</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <img src="./../images/hjgjj.png" alt="" />
                                                        <span className='ml-2 medium font__size--16 text__16-1024'>Verfied Company</span>
                                                    </div>
                                                </li>
                                            </ul>

                                            <NavLink to="/post-jobs/form" className='medium font__size--16 text__16-1024 btn btn__outlined--blue no__opacity color__blue shadow rounded__50 w-100'>Puchase Credit</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PostJobs