import React from 'react'
import { Fragment } from 'react'

const Comment = (props) => {
    return (
        <Fragment>
            <div className={"wrapper__community-activity d-flex " + (props.class ? props.class :"")}>
                <div className="position-relative flex-shrink-0 img">
                    <img src="./../images/dgsdggd.png" className="user" alt="" />
                </div>
                <div className="ml-3">
                    <div className="d-flex justify-content-between align-items-start w-100 mb-3">
                        <div className="">
                            <h5 className="medium font__size--16 text__16-1024 mb-0">Anika Herwitz</h5>
                            <p className="mb-0 normal font__size--14 text__14-1024 color__gray-1">Freelancer</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="normal font__size--14 text__14-1024 color__gray-1 mb-0">08-13-2021 08:36 PM</p>
                            <img src="./../images/more-horizontal.png" className="ml-3" alt="" />
                        </div>
                    </div>

                    <p className="normal font__size--16 text__16-1024 mb-3">Nunc congue et sit quis phasellus nisi etiam. Felis ut interdum luctus sit neque. Diam eget suscipit lacinia vitae duis. Mollis tellus tristique orci enim mauris. </p>

                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <img src="./../images/heart.png" alt="" />
                            <span className="normal font__size--14 text__14-1024 ml-1">32</span>
                        </div>
                        <div className="d-flex align-items-center mx-3">
                            <img src="./../images/message.png" alt="" />
                            <span className="normal font__size--14 text__14-1024 ml-1">1</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img src="./../images/Reply, Emails, Letter, Mail.png" alt="" />
                            <span className="normal font__size--14 text__14-1024 ml-1">Reply</span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Comment