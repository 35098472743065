import React, { useState, Fragment, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Opportunity from '../../component/Other/Opportunity'
import { useParams } from 'react-router-dom'
import { REACT_APP_IMAGE_BASE_URL } from '../../constant'

const Detail = (props) => {
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  //   console.log(id)
  //   console.log(props.props, 'This is  all blogs in details')
  let blog = props?.props?.filter((blog) => blog.id == id)
  blog = blog[0]
  useEffect(() => {
    ;(async function () {
      await props
      setLoading(false)
      // await getArticles()
    })()
  }, [])
  return (
    <Fragment>
      <div className='overflow-hidden'>
        <Navbar type='blue' />
        {loading ? (
          'Loading'
        ) : (
          <section>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 mb-4 mb-md-0'>
                  <h2 className='medium font__size--40 text__40-1024 text__40-md text__40-mm mb-3'>
                    {blog?.article1?.tittleText}
                  </h2>

                  <div className='d-flex align-items-center mb-4'>
                    <div className='wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue'>
                      <span className='wrapper__dot-list mr-2'></span>
                      {blog?.article1?.spanText}
                    </div>

                    <h5 className='mb-0 semi-bold font__size--14 text__14-1024 color__gray-2 ml-3'>
                      {blog?.article1?.date}
                    </h5>
                  </div>
                  <img
                    src={`${REACT_APP_IMAGE_BASE_URL}/${blog?.article1?.articlebg?.data?.attributes?.url}`}
                    className='images__detail-blog my-4'
                    alt=''
                  />
                  <div
                    className='normal font__size--18 text__18-1024 mb-4'
                    dangerouslySetInnerHTML={{
                      __html: blog?.article1?.content,
                    }}
                  ></div>

                  {/* <div className='wrapper__left-height position-relative d-flex align-items-center'>
                  <div className='line'></div>
                  <div className='ml-4'>
                    <h5 className='medium font__size--20 text__20-1024 color__gray-2 mb-4'>
                      “Inflation will put more pressure on chief procurement
                      officers to manage their departments strictly within
                      budget.”{' '}
                    </h5>
                    <h5 className='medium font__size--20 text__20-1024 color__gray-2 mb-0'>
                      - Ardent Partners Annual Research Report
                    </h5>
                  </div>
                </div> */}
                </div>
                <div className='col-md-4'>
                  <h4 className='medium font__size--24 text__24-1024 mb-4'>
                    Latest articles
                  </h4>

                  {props?.props?.slice(0, 5)?.map((items, index) => (
                    <>
                      <div className='d-inline-block' key={index}>
                        <div className='wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue'>
                          <span className='wrapper__dot-list mr-2'></span>{' '}
                          {items?.article1?.spanText}
                        </div>
                      </div>
                      <NavLink to={`/blog/${items?.id}`}>
                        <h5 className='medium font__size--24 text__24-1024 my-3'>
                          {items?.article1?.tittleText}
                        </h5>
                      </NavLink>
                      <p className='medium font__size--14 text__14-1024 color__gray-2'>
                        {items?.article1?.date}
                      </p>
                      <hr />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}

        {/* <Opportunity />`` */}

        <Footer />
      </div>
    </Fragment>
  )
}

export default Detail
