import React from 'react'
import { NavLink } from 'react-router-dom'

const CardJobs = (props) => {
  return (
    <NavLink to="/find-jobs/detail" className={"wrapper__card-jobs color__black " + (props.class ? props.class : "")}>
      <div className="head d-flex justify-content-between align-items-start mb-3">
        <div className="logo">
          <img src={props.data.logo} alt="" />
        </div>

        <div className="d-flex align-items-center">
          {
            props.type == "favorite" ? <img src="./../images/sasas.png" className='mr-3' alt="" /> : <img src="./../images/as (6).png" className='mr-3' alt="" />
          }

          <img src="./../images/as (5).png" alt="" />
        </div>
      </div>

      <div className="desc">
        <h5 className='semi-bold font__size--18 text__18-1024 mb-0'>{props.data.position}</h5>
        <p className='medium font__size--14 text__14-1024 color__blue'>{props.data.company}</p>

        <div className={"my-3 wrapper__list-data d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-start " + (props.class ? props.class : "")}>
          <div className="d-flex align-items-center">
            <img src="./../images/as (4).png" alt="" />
            <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>{props.data.experience}</span>
          </div>
          <div className="d-flex align-items-center">
            <img src="./../images/ds (3).png" alt="" />
            <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>{props.data.place}</span>
          </div>
          <div className="d-flex align-items-center">
            <img src="./../images/ds (2).png" alt="" />
            <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>{props.data.location}</span>
          </div>
        </div>

        <p className='normal font__size--14 text__14-1024 color__gray-2 clamp__3'>{props.data.desc}</p>

        <div className="wrapper__skill-list d-flex flex-wrap mb-3">
          {
            props.data.tag.map((obj) => {
              return <div className="items medium font__size--12 text__12-1024 color__gray-1">{obj}</div>
            })
          }
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className='semi-bold font__size--20 text__20-1024'>${props.data.price}</span>
            <span className='medium font__size--12 text__12-1024 color__gray-1 ml-1'>/Years</span>
          </div>
          <div className="d-flex align-items-center">
            <span className='medium font__size--14 text__14-1024 color__blue'>{props.data.time}</span>
            <span className='wrapper__dot mx-2'></span>
            <span className='medium font__size--14 text__14-1024 color__gray-1'>{props.data.applicant} applicants</span>
          </div>
        </div>

      </div>
    </NavLink>
  )
}

export default CardJobs