import React, { Fragment } from 'react'
import { useState } from 'react'
import { Range, getTrackBackground } from "react-range";

const FilterList = (props) => {
    const [toogle, setToogle] = useState(props.data.status ? props.data.status : false)

    const STEP = 0.1;
    const MIN = 0;
    const MAX = 2000;
    const rtl = false;

    const [values, setValues] = useState([props.data.min, props.data.max]);
    return (
        <div className={"wrapper__filter-list " + (toogle ? "active" : "")}>
            <div className="head pointer d-flex align-items-center justify-content-between" onClick={() => setToogle(!toogle)}>
                <span className='medium font__size--14 text__14-1024'>{props.data.title}</span>
                <img src="./../images/arrow.png" className='arrow ml-2' alt="" />
            </div>
            <div className="list">

                {
                    props.data.type == "list" ? props.data.list.map((obj, i) => {
                        return <div className="items d-flex align-items-center justify-content-between">
                            <div className="wrapper__checkbox position-relative d-flex align-items-center">
                                <input defaultChecked={obj.status} type="checkbox" id={obj.title + i} name={obj.title + i} value="Bike" />
                                <label className="circle box mb-0" for={obj.title + i}>
                                    <img src="./../images/check.png" className="check" alt="" />
                                </label>
                                <label className="medium font__size--14 text__14-1024 ml-3 mb-0" for={obj.title + i}>{obj.title}</label>
                            </div>
                            <div className="wrapper__number-filter medium font__size--12 text__12-1024 color__blue">{obj.number}</div>
                        </div>
                    }) : <Fragment>
                        <div className="d-flex align-items-center justify-content-between mb-0">
                            <span className='normal font__size--12 text__12-1024'>${Math.round(values[0])}</span>
                            <span className='normal font__size--12 text__12-1024'>${Math.round(values[1])}</span>
                        </div>
                        <Range
                            values={values}
                            step={STEP}
                            min={MIN}
                            max={MAX}
                            rtl={rtl}
                            onChange={(values) => {
                                setValues(values);
                            }}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: "36px",
                                        display: "flex",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: "5px",
                                            width: "100%",
                                            borderRadius: "4px",
                                            background: getTrackBackground({
                                                values,
                                                colors: ["#4864E114", "#4864E1", "#4864E114"],
                                                min: MIN,
                                                max: MAX,
                                                rtl,
                                            }),
                                            alignSelf: "center",
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "18px",
                                        width: "18px",
                                        borderRadius: "50%",
                                        border:"2px solid white",
                                        backgroundColor: "#4864E1",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "2px 2px 12px rgba(19, 35, 107, 0.24)",
                                        outlineStyle:"none"
                                    }}
                                ></div>
                            )}
                        />
                    </Fragment>
                }


            </div >
        </div >
    )
}

export default FilterList