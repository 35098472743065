import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Opportunity from '../../component/Other/Opportunity'
import SliderTestiGrid from '../../component/Slider/SliderTestiGrid'

const Clients = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="wrapper__card-about bg__blue">
                                    <h4 className="medium font__size--24 text__24-1024 color__white mb-3">Reviews on Ngul!</h4>
                                    <p className="normal font__size--24 text__24-1024 color__white lh__4 mb-0">See how clients and talent celebrate their wins and stay accountable for their work together, or join today to make your own connections.</p>

                                    <div>
                                        <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__white color__blue shadow rounded__50 my-4">Get Started</a>
                                    </div>

                                    <img src="./../images/fgfg (1).png" alt="" />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src="./../images/Image (3).png" className='images__review' alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h2 className='medium font__size--48 text__48-1024 text__48-sm mb-4'>Clients and talent leave each other <br className='d-none d-sm-block' /> 10k new reviews per week</h2>

                        <SliderTestiGrid />
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h3 className="medium font__size--48 text__48-1024 text__48-sm">Our numbers</h3>
                            <p className="normal font__size--16 text__16-1024">As the fastest-growing online Job board, our mission is to help <br className="d-none d-sm-block" /> great individuals connect with great companies.</p>
                        </div>

                        <div className="wrapper__number-wrap flex-wrap flex-md-nowrap d-flex align-items-center justify-content-center">
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (1).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">20,583<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Job Posted</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (4).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">3,896<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Successful hires</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (3).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">581<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Verified companies</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (2).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">100K<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Monthly visits</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Clients