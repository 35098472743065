import React from 'react'

const CardJobsDetail = () => {
    return (
        <div className="wrapper___detail-jobs active">
            <img src="./../images/Rectangle 11.png" className='bg__cover' alt="" />

            <div className="detail">
                <div className="profile mb-3">
                    <img src="./../images/ds (1).png" alt="" />
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div>
                        <h5 className='semi-bold font__size--18 text__18-1024 mb-0'>Sr. UI/UX Web Designer</h5>
                        <p className='mb-0 medium font__size--14 text__14-1024 color__blue'>Figma</p>
                    </div>
                    <div className="wrapper__icon-40">
                        <img src="./../images/Share.png" alt="" />
                    </div>
                </div>

                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <span className='semi-bold font__size--20'>$450K</span>
                        <span className='medium font__size--12 color__gray-1 ml-1'>/Years</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className='medium font__size--14 text__14-1024 color__blue'>17 hours ago</span>
                        <span className='wrapper__dot mx-2'></span>
                        <span className='medium font__size--14 text__14-1024 color__gray-1'>120 applicants</span>
                    </div>
                </div>

                <div className="mb-3 wrapper__list-data d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                        <img src="./../images/as (4).png" alt="" />
                        <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>1-3 Year</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <img src="./../images/ds (3).png" alt="" />
                        <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>Remote</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <img src="./../images/ds (2).png" alt="" />
                        <span className='medium font__size--14 text__14-1024 ml-2 color__gray-1'>San Francisco, California</span>
                    </div>
                </div>

                <h5 className='medium font__size--18 text__18-1024'>Role Overivew</h5>
                <p className='normal font__size--16 text__16-1024 color__gray-2'>Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish. Whether it’s consolidating tools,and time zones.</p>
                <p className='normal font__size--16 text__16-1024 color__gray-2'>We have a hybrid model of working, with hubs in San Francisco, New York, and London, and remote employees across the US and Europe... <span className='pointer semi-bold font__size--16 text__16-1024 color__blue'>More</span></p>

                <h5 className='medium font__size--18 text__18-1024'>Skill</h5>

                <div className="wrapper__skill-list d-flex flex-wrap mb-3">
                    <div className="items medium font__size--12 color__gray-1">Reseach</div>
                    <div className="items medium font__size--12 color__gray-1">App Design</div>
                    <div className="items medium font__size--12 color__gray-1">Web Design</div>
                    <div className="items medium font__size--12 color__gray-1">Prototyping</div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="wrapper__icon-56 flex-shrink-0">
                        <img src="./../images/as (6).png" alt="" />
                    </div>

                    <a href="#!" className='semi-bold font__size--16 text__16-1024 color__white btn btn__blue shadow rounded__50 w-100 ml-3'>Apply Now</a>
                </div>
            </div>
        </div>
    )
}

export default CardJobsDetail