import React, { Component, useState } from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom';

const Jobs = (props) => {
    const [inputPassword, setInputPassword] = useState(true);
    return (
        <Fragment>
            <div className="overflow-hidden position-relative w-100">
                <div className="row min-height-100">
                    <div className="col-md-6 d-none d-lg-block flex-fill bg__blue p-0 position-relative">
                        <div className="wrapper__wrap-sign h-100">
                            <h3 className='medium font__size--32 text__32-1024 text__32-xs color__white'>Hey There, Welcome to Ngul!</h3>
                            <p className='normal font__size--16 text__16-1024 color__white opacity__7'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim <br /> bibendum sollicitudin egestas accumsan, ut mattis. </p>
                        </div>
                        <img src="./../images/Find Job ( Search).png" className='images__1' alt="" />
                    </div>
                    <div className="col-lg-6 flex-fill p-0">
                        <div className="wrapper__wrap-sign h-100">
                            <div className="text-center">
                                <div
                                    class="navbar-brand semi-bold font__size--20 text__20-1024 bold color__black"
                                >
                                    <div className="d-flex align-items-center">
                                        <img src="./../images/adsad.png" alt="" />

                                        <span className="ml-2">Ngul!</span>
                                    </div>
                                </div>
                                <h3 className='bold font__size--32 text__32-1024 text__32-xs mt-3 mb-5'>Select Your Profile</h3>

                                <div className="row mb-5">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <div className="wrapper__select-profile">
                                            <img src="./../images/Icon.png" className='img mb-4' alt="" />
                                            <h5 className='medium color__blue font__size--20 text__20-1024'>I’m a freelancer</h5>
                                            <p className='normal font__size--16 text__16-1024 color__gray-1'>It’s easy to find your dream jobs here with us.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <div className="wrapper__select-profile">
                                            <img src="./../images/Icon (1).png" className='img mb-4' alt="" />
                                            <h5 className='medium color__blue font__size--20 text__20-1024'>I'm a client</h5>
                                            <p className='normal font__size--16 text__16-1024 color__gray-1'>It’s easy to find eployees here with us.</p>
                                        </div>
                                    </div>
                                </div>

                                <h5 className='mb-0 semi-bold font__size--16 text__16-1024 color__gray-2'>Already have an account? <a href="#!" className='color__blue'>Log In</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Jobs