import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import AccordionInfo from "../../component/Accordion/AccordionInfo";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Faq = (props) => {
    const listData = [
        {
            title: "What is Nguli?",
            desc: "Nisi interdum nisl est elementum tortor. Tellus vitae donec faucibus et ac sed facilisis egestas viverra. Facilisis mauris varius pellentesque convallis non leo. Dictum urna enim suspendisse venenatis porttitor nibh tempor id varius. Sit quis pharetra ac in egestas lectus."
        },
        {
            title: "How can I post a free job opening on Jobs?",
            desc: "Nisi interdum nisl est elementum tortor. Tellus vitae donec faucibus et ac sed facilisis egestas viverra. Facilisis mauris varius pellentesque convallis non leo. Dictum urna enim suspendisse venenatis porttitor nibh tempor id varius. Sit quis pharetra ac in egestas lectus."
        },
        {
            title: "What's the cost to post a featured job opening on Jobs?",
            desc: "Nisi interdum nisl est elementum tortor. Tellus vitae donec faucibus et ac sed facilisis egestas viverra. Facilisis mauris varius pellentesque convallis non leo. Dictum urna enim suspendisse venenatis porttitor nibh tempor id varius. Sit quis pharetra ac in egestas lectus."
        },
        {
            title: "Are there bulk discounts for featured job openings?",
            desc: "Nisi interdum nisl est elementum tortor. Tellus vitae donec faucibus et ac sed facilisis egestas viverra. Facilisis mauris varius pellentesque convallis non leo. Dictum urna enim suspendisse venenatis porttitor nibh tempor id varius. Sit quis pharetra ac in egestas lectus."
        },
        {
            title: "How long does it take to get a free job opening approved?",
            desc: "Nisi interdum nisl est elementum tortor. Tellus vitae donec faucibus et ac sed facilisis egestas viverra. Facilisis mauris varius pellentesque convallis non leo. Dictum urna enim suspendisse venenatis porttitor nibh tempor id varius. Sit quis pharetra ac in egestas lectus."
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-10">
                                <h4 className="medium font__size--24 text__24-1024 mb-4">Maybe your question is have been answered, <br className="d-none d-sm-block" /> check this out!</h4>
                                {
                                    listData.map((obj) => {
                                        return <div className="mb-4 pb-3">
                                            <AccordionInfo data={obj} />
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Faq;
