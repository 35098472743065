import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Privacy = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 mb-4 mb-md-0">

                                <h4 className="medium font__size--32 text__32-1024 text__32-xs">Privacy Policy</h4>
                                <p className="normal font__size--16 text__16-1024 color__gray-1 mb-4">The <span className="medium">Ngul!</span> Terms of Service is comprised of the following agreements:</p>

                                <ul className="wrapper__list-number">
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center">
                                            <div className="number">
                                                1
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">User Agrement</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center active">
                                            <div className="number">
                                                2
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">Privacy Policy</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center">
                                            <div className="number">
                                                3
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">Direct Contracts Terms</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7 ml-md-5">
                                <h4 className="medium font__size--20 text__20-1024 mb-4">User Agrement</h4>

                                <p className="normal font__size--16 text__16-1024 mb-4">Neque ut facilisi risus aenean ac nibh massa. Sed accumsan et aliquet feugiat facilisi mauris nibh aliquet. Eu in scelerisque in feugiat adipiscing cras mollis non. Etiam tristique risus luctus cursus ut. Nunc congue et sit quis phasellus nisi etiam. Felis ut interdum luctus sit neque. Diam eget suscipit lacinia vitae duis. Mollis tellus tristique orci enim mauris. Morbi urna at diam lacus imperdiet amet ut faucibus. Magnis faucibus ac neque consectetur. </p>

                                <p className="medium font__size--16 text__16-1024 mb-4">Viverra dictum nullam hac adipiscing venenatis. Malesuada nibh nunc consequat hendrerit egestas sed. Dui hendrerit diam amet tincidunt netus condimentum viverra. Aliquam eget at in tempus aliquam lorem. Eget nulla quis scelerisque interdum scelerisque posuere sit.</p>

                                <h5 className="medium font__size--16 text__16-1024">1. How It Works</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">Viverra dictum nullam hac adipiscing venenatis. Malesuada nibh nunc consequat hendrerit egestas sed. Dui hendrerit diam amet tincidunt netus condimentum viverra. Aliquam eget at in tempus aliquam lorem. Eget nulla quis scelerisque interdum scelerisque posuere sit.</p>

                                <h5 className="medium font__size--16 text__16-1024">2. Freelancer Fees</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">Neque ut facilisi risus aenean ac nibh massa. Sed accumsan et aliquet feugiat facilisi mauris nibh aliquet. Eu in scelerisque in feugiat adipiscing cras mollis non. Etiam tristique risus luctus cursus ut. Nunc congue et sit quis phasellus nisi etiam. Felis ut interdum luctus sit neque. Diam eget suscipit lacinia vitae duis. Mollis tellus tristique orci enim mauris. Morbi urna at diam lacus imperdiet amet ut faucibus. Magnis faucibus ac neque consectetur. </p>
                       
                                <h5 className="medium font__size--16 text__16-1024">3. Client Fees</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">Convallis enim urna turpis facilisi magna pellentesque enim. Volutpat diam sed varius odio elementum mattis. Pharetra felis scelerisque hendrerit aliquet elementum. Risus viverra aliquam nec lectus lacus. Purus dis mi volutpat arcu sed fringilla id magna dolor. Dignissim phasellus proin tellus felis sollicitudin orci facilisi. Ut ornare vitae sed egestas volutpat. Condimentum enim orci scelerisque at. Ornare erat eget tempus tristique neque id magna aliquet rhoncus. Ornare magna urna at consequat ultrices adipiscing auctor. Quam amet vivamus sed id aenean nibh laoreet elit quis. Nibh nulla molestie risus consectetur augue viverra. Vivamus arcu enim porta quisque proin maecenas. A tellus bibendum neque nunc magna.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Privacy;
