import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { REACT_APP_IMAGE_BASE_URL } from '../../constant'

const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([])
  const [footerSocials, setfooterSocials] = useState({})
  const getFooterLinks = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/footer?populate=*&populate=footer.logo&populate=footer.FooterLinks`,
        config
      )

      if (response.status === 200 || 201) {
        const data = response?.data?.data?.attributes.footer
        setFooterLinks(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getSocials = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/social?populate=*&populate=social.socials.icon&populate=social.socLinks`,
        config
      )

      if (response.status === 200 || 201) {
        const data = response?.data?.data?.attributes.social
        setfooterSocials(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // console.log(footerSocials, ' Footer socials')
  useEffect(() => {
    getFooterLinks()
    getSocials()
  }, [])
  // console.log(footerLinks, ' This are my Footer links')
  return (
    <section className='bg__darkblue pb-4'>
      <div className='container'>
        <div className='row mb-4 mb-sm-5'>
          <div className='col-md-6 mb-4 mb-lg-0 col-lg-4'>
            <div className='d-flex align-items-center mb-3 w-25'>
              <img
                src={`${REACT_APP_IMAGE_BASE_URL}/${footerLinks?.logo?.data?.attributes?.url}`}
                alt=''
              />
              {/* <h5 className='semi-bold font__size--24 text__24-1024 color__white ml-2 mb-0'>
                Nugi!
              </h5> */}
            </div>
            <p className='normal font__size--16 text__16-1024 color__white'>
              {footerLinks.descText}
            </p>
          </div>
          <div className='col-md-6 mb-4 mb-lg-0 col-lg-6 d-flex align-items-center justify-content-center'>
            <div className='wrapper__menu-footer d-flex flex-wrap align-items-center justify-content-start  justify-content-md-center'>
              {footerLinks?.FooterLinks?.map((links, index) => (
                <a
                  href={links.url}
                  className='medium font__size--16 text__16-1024 color__white opacity__5 hover'
                  key={index}
                >
                  {links.Links}
                </a>
              ))}
            </div>
          </div>
          {/* <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
            <h5 className='medium font__size--16 text__16-1024 color__white opacity__5 mb-4'>Newsletter</h5>

            <div className="d-flex align-items-center">
              <input type="text" placeholder='Your email here' className='normal color__white font__size--16 text__16-1024 wrapper__input-field sub' />
              <button className='semi-bold font__size--16 text__16-1024 btn btn__blue color__white shadow rounded__50 ml-3'>Send</button>
            </div>
          </div> */}
        </div>
        <img src='./../images/Line@2x.png' className='w-100 mb-4' alt='' />
        <div className='d-md-flex aligen-items-center justify-content-between position-relative'>
          <p className='mb-0 normal font__size--16 text__16-1024 color__white'>
            {footerSocials?.copyright}
          </p>
          <div className='wrapper__sosmed center d-flex align-items-center justify-content-md-center my-3 my-md-0'>
            {footerSocials?.socials?.map((icons, index) => (
              <a
                href={icons?.link}
                key={index}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={`${REACT_APP_IMAGE_BASE_URL}/${icons?.icon?.data?.attributes?.url}`}
                  alt=''
                />
              </a>
            ))}
            {/* <a href='#!'>
              <img src='./../images/svg (1).png' alt='' />
            </a>
            <a href='#!'>
              <img src='./../images/svg (2).png' alt='' />
            </a>
            <a href='#!'>
              <img src='./../images/svg (3).png' alt='' />
            </a>
            <a href='#!'>
              <img src='./../images/svg (4).png' alt='' />
            </a> */}
          </div>

          <div className='d-flex align-items-center'>
            <NavLink
              to='/terms'
              className='medium font__size--16 text__16-1024 color__white opacity__5 hover'
            >
              Terms of Service
            </NavLink>
            <NavLink
              to='/privacy'
              className='medium font__size--16 text__16-1024 color__white opacity__5 hover ml-3'
            >
              Privacy Policy
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
