import React, { useState, Fragment, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import CardArticle from '../../component/Card/CardArticle'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Opportunity from '../../component/Other/Opportunity'
import axios from 'axios'
import { REACT_APP_IMAGE_BASE_URL } from '../../constant'
import './loader.css'

const Blog = (props) => {
  const [banners, setBanners] = useState({})
  // const [articles, setArticles] = useState([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const location = useLocation()
  const [isLoading, setisLoading] = useState(true)
  const getBanner = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/banner?populate=blogBanner.banner1.icon&populate=blogBanner.banners2.bg&populate=blogBanner.banner_3.bg&populate=blogBanner.banner3.bg`,
        config
      )

      if (response.status === 200 || 201) {
        const data = response?.data?.data?.attributes.blogBanner
        setBanners(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // const getArticles = async () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
  //     },
  //   }
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/article?populate=*&populate=articles.article1.articlebg`,
  //       config
  //     )
  //     if (response.status === 200 || 201) {
  //       const data = response?.data?.data?.attributes.articles
  //       setArticles(data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // getBanner()

  useEffect(() => {
    ;(async function () {
      await getBanner()
      // await getArticles()
      setisLoading(false)
    })()
  }, [])
  const { banner1, banners2, banner3, banner_3 } = banners
  //   console.log(articles, 'This is my articles')

  return (
    <Fragment>
      {isLoading ? (
        <div className='loader-wrap'>
          <span className='loader'></span>
          {/* <h4>loading.....</h4> */}
        </div>
      ) : (
        <div className='overflow-hidden'>
          <Navbar type='blue' />

          <section>
            <div className='container try'>
              <div className='row'>
                <div className='col-lg-5 mb-4 mb-lg-0'>
                  <div className='wrapper__card-about bg__blue'>
                    <img
                      src={`${REACT_APP_IMAGE_BASE_URL}/${banner1?.icon?.data?.attributes?.url}`}
                      className='mb-4'
                      alt=''
                    />

                    <h4 className='medium font__size--32 text__32-1024 color__white mb-3'>
                      {banner1?.HeaderText}
                      <br />
                    </h4>
                    <p className='normal font__size--24 text__24-1024 color__white lh__4 mb-5'>
                      {banner1?.desc}
                    </p>

                    <img src='./../images/fgfg (1).png' alt='' />
                  </div>
                </div>

                <div className='col-lg-7'>
                  <div className='wrapper__photo-grid blog d-flex'>
                    <div className='position-relative item first'>
                      <img
                        src={`${REACT_APP_IMAGE_BASE_URL}/${banners2?.bg?.data?.attributes?.url}`}
                        className='img-1'
                        alt=''
                      />
                      <div className='bg'></div>
                      <div className='tag medium font__size--16 text__16-1024 color__white'>
                        {banners2?.desc}
                      </div>
                    </div>
                    <div className='item wrap-1'>
                      <div className='position-relative wrap-1'>
                        <img
                          src={`${REACT_APP_IMAGE_BASE_URL}/${banner3?.bg?.data?.attributes?.url}`}
                          alt=''
                        />
                        <div className='bg'></div>
                        <div className='tag medium font__size--16 text__16-1024 color__white'>
                          {banner3?.desc}
                        </div>
                      </div>
                      <div className='position-relative wrap-1'>
                        <img
                          src={`${REACT_APP_IMAGE_BASE_URL}/${banner_3?.bg?.data?.attributes?.url}`}
                          alt=''
                        />
                        <div className='bg'></div>
                        <div className='tag medium font__size--16 text__16-1024 color__white'>
                          {banner_3?.desc}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className='container'>
              <h4 className='medium font__size--32 text__32-1024 mb-5'>
                Latest Articles
              </h4>

              <div className='row mb-4'>
                {props?.props?.map((obj, index) => {
                  return (
                    <div className='col-md-6 col-lg-4 mb-4' key={index}>
                      <CardArticle data={obj} />
                    </div>
                  )
                })}
              </div>

              {/* <div className='d-flex align-items-center justify-content-between'>
              <div className='wrapper__pagination d-flex align-items-center'>
                <svg
                  className='pointer left'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M17.6799 20.7067L12.9866 16L17.6799 11.2933'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
                <div className='mx-2 list'>
                  <div className='dot active'></div>
                  <div className='dot'></div>
                  <div className='dot'></div>
                </div>
                <svg
                  className='pointer right'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M14.3201 20.7067L19.0134 16L14.3201 11.2933'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <h5 className='mb-0 medium font__size--18 text__18-1024'>1/4</h5>
            </div> */}
            </div>
          </section>

          {/* <Opportunity /> */}

          <Footer />
        </div>
      )}
    </Fragment>
  )
}

export default Blog
