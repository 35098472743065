import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import CardJobs from '../../component/Card/CardJobs'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const FindJobs = () => {
    const findJobs = [
        {
            logo: "./../images/ds (1).png",
            position: "Sr. UI/UX Web Designer",
            company: "Figma",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/google.png",
            position: "Sr. UI/UX Designer",
            company: "Google",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "410K",
            time: "20 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/medium.png",
            position: "Sr. UI/UX Web Designer",
            company: "Medium",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/dribbble.png",
            position: "Sr. UI/UX Web Designer",
            company: "Dribbble",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/ds (1).png",
            position: "Sr. UI/UX Web Designer",
            company: "Figma",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/google.png",
            position: "Sr. UI/UX Designer",
            company: "Google",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "410K",
            time: "20 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/medium.png",
            position: "Sr. UI/UX Web Designer",
            company: "Medium",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/dribbble.png",
            position: "Sr. UI/UX Web Designer",
            company: "Dribbble",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar auth={true} type="blue" />

                <section className='pt-0'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-4 mb-lg-0">
                                <div className="wrapper__side-dashboad position-relative">
                                    <div className="text-center mb-4">
                                        <img src="./../images/fhfhfh.png" className='images__user-profile mb-3' alt="" />
                                        <h5 className='semi-bold font__size--18 text__18-1024 mb-0'>Brandon Curtis</h5>
                                        <p className='mb-0 medium font__size--14 text__14-1024 color__gray-1'>UI/UX Designer</p>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className='medium font__size--14 text__14-1024'>Location</h5>
                                        <p className='medium font__size--14 text__14-1024 color__gray-2 mb-0'>Los Angles, 212, UK</p>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className='medium font__size--14 text__14-1024'>Website Portofolio</h5>
                                        <a href='#!' className='medium font__size--14 text__14-1024 mb-0 color__blue'>projects.brandoncurtis.com</a>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className='medium font__size--14 text__14-1024 mb-3'>Skill</h5>
                                        <div className="wrapper__skill-list d-flex flex-wrap">
                                            <div className="items medium font__size--14 text__14-1024 color__gray-1">Research</div>
                                            <div className="items medium font__size--14 text__14-1024 color__gray-1">Wireframe</div>
                                            <div className="items medium font__size--14 text__14-1024 color__gray-1">App Design</div>
                                            <div className="items medium font__size--14 text__14-1024 color__gray-1">Web Design</div>
                                            <div className="items medium font__size--14 text__14-1024 color__gray-1">Prototyping</div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className='medium font__size--14 text__14-1024 mb-3'>Tools Used</h5>
                                        <div className="wrapper__skill-tool d-flex flex-wrap">
                                            <img src="./../images/Figma.png" alt="" />
                                            <img src="./../images/Figma (1).png" alt="" />
                                            <img src="./../images/Illustrator.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className='medium font__size--14 text__14-1024 mb-3'>Contact</h5>
                                        <div className="d-flex align-items-center mb-3">
                                            <img src="./../images/Skype.png" className='images__sosmed' alt="" />
                                            <div className='ml-2'>
                                                <p className='mb-0 normal font__size--12 text__12-1024 mb-0 color__gray-1'>Skype</p>
                                                <h5 className='mb-0 medium font__size--14 text__14-1024'>brandoncurtis</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <img src="./../images/Skype (1).png" className='images__sosmed' alt="" />
                                            <div className='ml-2'>
                                                <p className='mb-0 normal font__size--12 text__12-1024 mb-0 color__gray-1'>Facebook</p>
                                                <h5 className='mb-0 medium font__size--14 text__14-1024'>brd.curtis</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <img src="./../images/Skype (2).png" className='images__sosmed' alt="" />
                                            <div className='ml-2'>
                                                <p className='mb-0 normal font__size--12 text__12-1024 mb-0 color__gray-1'>Instagram</p>
                                                <h5 className='mb-0 medium font__size--14 text__14-1024'>brandon.curtis</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="wrapper__content-menu">
                                    <div className="mb-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <div className="wrapper__search  w-100 d-flex align-items-center">
                                                <img src="./../images/add (1).png" alt="" />
                                                <input type="text" placeholder='Search for job..' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                            </div>
                                            <div className="wrapper__location my-3 my-sm-0 mx-sm-3 d-flex align-items-center">
                                                <img src="./../images/add (2).png" alt="" />
                                                <input type="text" placeholder='Location' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                            </div>
                                            <NavLink to="/find-jobs/search" className='btn btn__blue btn__search color__white shadow rounded__50 flex-shrink-0'>
                                                <div className="d-flex align-items-center">
                                                    <img src="./../images/search-normal (1).png" alt="" />
                                                    <span className='ml-2 semi-bold font__size--16 text__16-1024'>Search</span>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        {
                                            findJobs.map((obj) => {
                                                return <div className="col-md-6 mb-4">
                                                    <CardJobs data={obj} />
                                                </div>
                                            })
                                        }

                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="mb-0 medium font__size--18 text__18-1024">1/8</h5>
                                        <div className="wrapper__pagination d-flex align-items-center">
                                            <svg className='pointer left' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.6799 20.7067L12.9866 16L17.6799 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div className="mx-2 list">
                                                <div className="dot active"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                            <svg className='pointer right' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M14.3201 20.7067L19.0134 16L14.3201 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </Fragment>
    )
}

export default FindJobs