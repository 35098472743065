import React from 'react'
import { NavLink } from 'react-router-dom'

const Opportunity = () => {
    return (
        <section className="bg__blue">
            <div className="container">
                <div className="d-lg-flex text-center text-lg-left align-items-center justify-content-between">
                    <div>
                        <h4 className="medium font__size--48 text__48-1024 text__48-sm color__white">Find your next great opportunity!</h4>
                        <p className="normal font__size--16 text__16-1024 color__white mb-0">We are taking action to help our freelancers, our clients, and the people of the world— <br />and so can you.</p>
                    </div>

                    <NavLink to="support" className="semi-bold font__size--16 text__16-1024 color__blue btn btn__white shadow rounded__50 mt-4 mt-lg-0 ml-lg-3">Get Started</NavLink>
                </div>
            </div>
        </section>
    )
}

export default Opportunity