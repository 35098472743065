import React from 'react'
import { NavLink } from 'react-router-dom'
import { REACT_APP_IMAGE_BASE_URL } from '../../constant'

const CardArticle = (props) => {
  // console.log(props, 'This is my props')
  return (
    <div className='wrapper__card-article'>
      <div className='position-relative cover mb-3'>
        <img
          src={`${REACT_APP_IMAGE_BASE_URL}/${props?.data?.article1?.articlebg?.data?.attributes?.url}`}
          alt=''
        />
        <div className='tag medium font__size--14 text__14-1024 color__white'>
          {props?.data?.article1?.spanText}
        </div>
      </div>

      <div className='desc'>
        <h5 className='medium font__size--20 text__20-1024 mb-3'>
          {props?.data?.article1?.tittleText}
        </h5>
        <p className='normal font__size--16 text__16-1024 my-3'>
          {props?.data?.article1?.descText}
        </p>

        <div className='d-flex align-items-center justify-content-between'>
          <div className='medium font__size--14 text__14-1024'>
            {props.data.article1.date}
          </div>
          <NavLink
            to={`/blog/${props?.data?.id}`}
            className='d-flex align-items-center medium font__size--14 text__14-1024 color__blue'
          >
            Read More
            <img src='./../images/arrow-right.png' className='ml-1' alt='' />
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default CardArticle
