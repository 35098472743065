import React from 'react'
import { useState } from 'react'

const AccordionInfo = (props) => {
    const [active, setActive] = useState(false)
    return (
        <div className={'wrapper__accordion-info ' + (active ? "active" : "")}>
            <div className="head d-flex align-items-center" onClick={() => setActive(!active)}>
                <h5 className='mb-0 medium font__size--20 text__20-1024 w-100'>{props.data.title}</h5>
                <img src="./../images/arrow-circle-down.png" className='ml-3 arrow' alt="" />
            </div>
            <div className="desc normal font__size--16 text__16-1024 color__gray-1">
                {props.data.desc}
            </div>
        </div>
    )
}

export default AccordionInfo