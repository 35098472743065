import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ModalPostJobs from '../../component/Modal/ModalPostJobs'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import SliderForm from '../../component/Slider/SliderForm'

const PostJobsForm = () => {
    const [Active, setActive] = useState(0)

    const [toogleModal, setToogleModal] = useState(false)
    useEffect(() => {
        var menu = document.querySelector('body')
        if (toogleModal) {
            menu.classList.toggle('modal-open');
        } else {
            menu.classList.remove('modal-open');
        }
    }, [toogleModal])
    return (
        <div className="overflow-hidden">
            <ModalPostJobs toogleModal={toogleModal} setToogleModal={(e) => setToogleModal(e)} />
            <Navbar auth={true} type="blue" />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 mb-5 mb-md-0">
                            <h4 className='medium font__size--32 text__32-1024 text__32-xs'>Post a Job</h4>
                            <p className='normal font__size--16 text__16-1024 color__gray-2 mb-4'>To submit a job opening for approval, please fill the following form. If you have any questions in the process, please reach out to support@jobs.com.</p>

                            <h5 className='medium font__size--20 text__20-1024 mb-3'>Form Steps</h5>

                            <div className="position-relative wrapper__list-step-form">
                                <div className={"item d-flex align-items-center " + (Active == 0 ? "active" : "")}>
                                    <div className="round d-flex align-items-center">
                                        1
                                    </div>
                                    <span className='ml-2 medium font__size--16 text__16-1024'>Job Information</span>
                                </div>
                                <div className={"item d-flex align-items-center " + (Active == 1 ? "active" : "")}>
                                    <div className="round d-flex align-items-center">
                                        2
                                    </div>
                                    <span className='ml-2 medium font__size--16 text__16-1024'>Company Information</span>
                                </div>
                                <div className={"item d-flex align-items-center " + (Active == 2 ? "active" : "")}>
                                    <div className="round d-flex align-items-center">
                                        3
                                    </div>
                                    <span className='ml-2 medium font__size--16 text__16-1024'>Recruiter Information</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 offset-lg-1">
                            <SliderForm Active={Active} setActive={(e) => setActive(e)} toogleModal={toogleModal} setToogleModal={(e) => setToogleModal(e)} />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PostJobsForm