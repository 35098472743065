import React from 'react'
import { NavLink } from 'react-router-dom'

const ModalPostJobs = (props) => {
    return (
        <div class={"wrapper__modal-connect post modal fade " + (props.toogleModal ? "show" : "")} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="content__modal-connect text-center">
                            <img src="./../images/tick-circle.png" className="mb-4" alt="" />
                            <h2 className="medium font__size--32 text__32-1024 text__32-xs">Thanks for Submit!</h2>
                            <p className="normal font__size--16 text__16-1024 color__gray-1 mb-4">You’will receive a folloeup email from our team shortiy. Keep an <br /> eye on your inbox.</p>
                            <NavLink to="/find-jobs" className="medium font__size--16 text__16-1024 color__blue">Back to Home</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPostJobs