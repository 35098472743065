import React, { Component, useState } from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom';

const Signup = (props) => {
    const [inputPassword, setInputPassword] = useState(true);
    return (
        <Fragment>
            <div className="overflow-hidden position-relative w-100">
                <div className="row min-height-100">
                    <div className="col-md-6 d-none d-lg-block flex-fill bg__blue p-0 position-relative">
                        <div className="wrapper__wrap-sign h-100">
                            <h3 className='medium font__size--32 text__32-1024 text__32-xs color__white'>Find Great Work</h3>
                            <p className='normal font__size--16 text__16-1024 color__white opacity__7'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim <br className='d-none d-xl-block' /> bibendum sollicitudin egestas accumsan, ut mattis. </p>
                        </div>
                        <img src="./../images/adasdsa.png" className='images__2' alt="" />
                    </div>
                    <div className="col-lg-6 flex-fill p-0">
                        <div className="wrapper__wrap-sign h-100">
                            <div className="text-center">
                            <div
                                    class="navbar-brand medium font__size--20 text__20-1024 bold color__black"
                                >
                                    <div className="d-flex align-items-center">
                                        <img src="./../images/adsad.png" alt="" />

                                        <span className="ml-2">Ngul!</span>
                                    </div>
                                </div>
                                <h3 className='medium font__size--32 text__32-1024 text__32-xs  mt-3 mb-5'>Create a Ngul! <br /> account</h3>

                           
                                <a href="#!" className='btn__auth-account mb-4'>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src="./../images/Icon - Apple.png" alt="" />
                                        <span className='medium color__black fotn__size--16 text__16-1024 ml-3'>Continue with Apple</span>
                                    </div>
                                </a>

                                <a href="#!" className='btn__auth-account'>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src="./../images/Icon - Google.png" alt="" />
                                        <span className='medium color__black fotn__size--16 text__16-1024 ml-3'>Continue with Google</span>
                                    </div>
                                </a>

                                <div className="wrapper__line-auth position-relative text-center my-4">
                                    <div className="line"></div>
                                    <h5 className='medium font__size--14 text__14-1024 d-inline-block position-relative z-2 color__gray-1 mb-0 text-center'>Or Sign Up with</h5>
                                </div>



                                <div className="mb-4 text-left">
                                    <label className="medium font__size--14 text__14-1024 text__14-1024 color__gray-3">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        className="font__size--16 text__16-1024 normal wrapper__input-field color__gray-2 form-control"
                                        placeholder="Enter your name"
                                    />
                                </div>
                                <div className="mb-4 text-left">
                                    <label className="medium font__size--14 text__14-1024 text__14-1024 color__gray-3">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className="font__size--16 text__16-1024 normal wrapper__input-field color__gray-2 form-control"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div className="mb-4 text-left">
                                    <label className="medium color__gray-3 font__size--14 text__14-1024 text__14-1024">
                                        Password
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            type={inputPassword ? "password" : "text"}
                                            className="font__size--16 text__16-1024 normal wrapper__input-field color__gray-2 form-control"
                                            placeholder="Enter your password"
                                        />
                                        <svg className="pointer icon-hide"
                                            onClick={() => setInputPassword(!inputPassword)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.10195 11.4599C6.95945 11.4599 6.81695 11.4074 6.70445 11.2949C6.08945 10.6799 5.75195 9.86244 5.75195 8.99994C5.75195 7.20744 7.20695 5.75244 8.99945 5.75244C9.86195 5.75244 10.6795 6.08994 11.2945 6.70494C11.3995 6.80994 11.4595 6.95244 11.4595 7.10244C11.4595 7.25244 11.3995 7.39494 11.2945 7.49994L7.49945 11.2949C7.38695 11.4074 7.24445 11.4599 7.10195 11.4599ZM8.99945 6.87744C7.82945 6.87744 6.87695 7.82994 6.87695 8.99994C6.87695 9.37494 6.97445 9.73494 7.15445 10.0499L10.0495 7.15494C9.73445 6.97494 9.37445 6.87744 8.99945 6.87744Z" fill="#09080D" />
                                            <path d="M4.20086 13.8826C4.07336 13.8826 3.93836 13.8376 3.83336 13.7476C3.03086 13.0651 2.31086 12.2251 1.69586 11.2501C0.900859 10.0126 0.900859 7.99511 1.69586 6.75011C3.52586 3.88511 6.18836 2.23511 9.00086 2.23511C10.6509 2.23511 12.2784 2.80511 13.7034 3.87761C13.9509 4.06511 14.0034 4.41761 13.8159 4.66511C13.6284 4.91261 13.2759 4.96511 13.0284 4.77761C11.7984 3.84761 10.4034 3.36011 9.00086 3.36011C6.57836 3.36011 4.26086 4.81511 2.64086 7.35761C2.07836 8.23511 2.07836 9.76511 2.64086 10.6426C3.20336 11.5201 3.84836 12.2776 4.56086 12.8926C4.79336 13.0951 4.82336 13.4476 4.62086 13.6876C4.51586 13.8151 4.35836 13.8826 4.20086 13.8826Z" fill="#09080D" />
                                            <path d="M8.99993 15.7651C8.00242 15.7651 7.02742 15.5626 6.08992 15.1651C5.80492 15.0451 5.66992 14.7151 5.78992 14.4301C5.90992 14.1451 6.23992 14.0101 6.52492 14.1301C7.31992 14.4676 8.15242 14.6401 8.99242 14.6401C11.4149 14.6401 13.7324 13.1851 15.3524 10.6426C15.9149 9.76512 15.9149 8.23512 15.3524 7.35762C15.1199 6.99012 14.8649 6.63762 14.5949 6.30762C14.3999 6.06762 14.4374 5.71512 14.6774 5.51262C14.9174 5.31762 15.2699 5.34762 15.4724 5.59512C15.7649 5.95512 16.0499 6.34512 16.3049 6.75012C17.0999 7.98762 17.0999 10.0051 16.3049 11.2501C14.4749 14.1151 11.8124 15.7651 8.99993 15.7651Z" fill="#09080D" />
                                            <path d="M9.51671 12.2025C9.25421 12.2025 9.01421 12.015 8.96171 11.745C8.90171 11.4375 9.10421 11.145 9.41171 11.0925C10.2367 10.9425 10.9267 10.2525 11.0767 9.42751C11.1367 9.12001 11.4292 8.92501 11.7367 8.97751C12.0442 9.03751 12.2467 9.33002 12.1867 9.63752C11.9467 10.935 10.9117 11.9625 9.62171 12.2025C9.58421 12.195 9.55421 12.2025 9.51671 12.2025Z" fill="#09080D" />
                                            <path d="M1.50008 17.0625C1.35758 17.0625 1.21508 17.01 1.10258 16.8975C0.885078 16.68 0.885078 16.32 1.10258 16.1025L6.70508 10.5C6.92258 10.2825 7.28258 10.2825 7.50008 10.5C7.71758 10.7175 7.71758 11.0775 7.50008 11.295L1.89758 16.8975C1.78508 17.01 1.64258 17.0625 1.50008 17.0625Z" fill="#09080D" />
                                            <path d="M10.8966 7.66508C10.7541 7.66508 10.6116 7.61258 10.4991 7.50008C10.2816 7.28258 10.2816 6.92258 10.4991 6.70508L16.1016 1.10258C16.3191 0.885078 16.6791 0.885078 16.8966 1.10258C17.1141 1.32008 17.1141 1.68008 16.8966 1.89758L11.2941 7.50008C11.1816 7.61258 11.0391 7.66508 10.8966 7.66508Z" fill="#09080D" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="wrapper__checkbox position-relative d-flex align-items-center my-3">
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                    <label className="circle" for="vehicle1">
                                        <img src="./../images/check.png" className="check" alt="" />
                                    </label>
                                    <label className="medium font__size--14 text__14-1024 ml-2" for="vehicle1">I agree to Claraa <a href="#!" className='color__blue'>Terms of Use</a> and <a href="#!" className='color__blue'>Privacy Policy</a></label>
                                </div>

                                <NavLink to="/find-jobs" className='medium font__size--16 text__16-1024 text__16-1024 btn__auth w-100 rounded__50'>Get Started</NavLink>

                                <div className="text-center mt-3 medium font__size--16 text__16-1024 text__16-1024 color__gray-2">
                                    Already have an account? <NavLink to="/login" className='color__blue'>Log In</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Signup