import React, { Fragment } from 'react'
import CardJobs from '../../component/Card/CardJobs'
import CardJobsDetail from '../../component/Card/CardJobsDetail'
import FilterList from '../../component/Other/FilterList'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const FindJobsDetail = () => {
    const detail = {
        logo: "./../images/ds (1).png",
        position: "Sr. UI/UX Web Designer",
        company: "Figma",
        experience: "1-3 Year",
        place: "Remote",
        location: "San Francisco, California",
        desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
        tag: ["App Design", "Web Design", "Interaction Design"],
        price: "450K",
        time: "17 hours ago",
        applicant: "120"
    };

    const findJobs = [
        {
            logo: "./../images/google.png",
            position: "Sr. UI/UX Designer",
            company: "Google",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "410K",
            time: "20 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/medium.png",
            position: "Sr. UI/UX Web Designer",
            company: "Medium",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/dribbble.png",
            position: "Sr. UI/UX Web Designer",
            company: "Dribbble",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
    ]

    const Filter = [
        {
            title: "Category",
            status: true,
            type: "list",
            list: [
                {
                    title: "Design",
                    number: "324",
                    status: true
                },
                {
                    title: "Development",
                    number: "321",
                    status: false
                },
                {
                    title: "Marketers",
                    number: "142",
                    status: false
                },
            ]
        },
        {
            title: "Job Type",
            status: true,
            type: "list",
            list: [
                {
                    title: "Part Time",
                    number: "232",
                    status: false
                },
                {
                    title: "Full-time",
                    number: "121",
                    status: true
                },
                {
                    title: "Contract",
                    number: "213",
                    status: false
                },
                {
                    title: "Internship",
                    number: "420",
                    status: false
                },
            ]
        },
        {
            title: "Seniority Level",
            status: true,
            type: "list",
            list: [
                {
                    title: "Senior",
                    number: "217",
                    status: true
                },
                {
                    title: "Midle",
                    number: "312",
                    status: false
                },
                {
                    title: "Junior",
                    number: "210",
                    status: false
                },
            ]
        },
        {
            title: "Salary Range",
            status: true,
            type: "range",
            min: 500,
            max: 1500,
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar auth={true} type="blue" />

                <section className='pt-0'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-4 mb-lg-0">
                                <div className="wrapper__side-dashboad position-relative">
                                    <h4 className='semi-bold font__size--16 text__16-1024 mb-4'>Filter by</h4>

                                    {
                                        Filter.map((obj) => {
                                            return <div className="mb-4">
                                                <FilterList data={obj} />
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="wrapper__content-menu">
                                    <div className="mb-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <div className="wrapper__search  w-100 d-flex align-items-center">
                                                <img src="./../images/add (1).png" alt="" />
                                                <input type="text" placeholder='Search for job..' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                            </div>
                                            <div className="wrapper__location  my-3 my-sm-0 mx-sm-3 d-flex align-items-center">
                                                <img src="./../images/add (2).png" alt="" />
                                                <input type="text" placeholder='Location' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                            </div>
                                            <button className='btn btn__blue btn__search color__white shadow rounded__50 flex-shrink-0'>
                                                <div className="d-flex align-items-center">
                                                    <img src="./../images/search-normal (1).png" alt="" />
                                                    <span className='ml-2 semi-bold font__size--16 text__16-1024'>Search</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mb-4 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                                        <div className="d-flex align-items-center mb-3 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <img src="./../images/arrow-left.png" alt="" /> <span className='ml-2 medium font__size--12 text__12-1024'>Back</span>
                                            </div>

                                            <div className="ml-3 medium font__size--18 text__18-1024 color__gray-2">
                                                <span className='color__blue semi-bold'>2.110</span> jobs found
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <span className='medium font__size--16 text__16-1024 color__gray-2'>Sort by:</span>
                                                <div class="dropdown ml-2">
                                                    <button class="wrapper__btn-filter dropdown-toggle semi-bold font__size--12 text__12-1024" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Relevance
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Another action</a>
                                                        <a class="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrapper__filter-icon ml-3">
                                                <img src="./../images/filter.png" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-4">

                                        <div className="order-md-1 order-12 col-md-6">
                                            <div className="mb-4">
                                                <CardJobs data={detail} class="active" />
                                            </div>

                                            <h4 className='medium font__size--20 text__20-1024 mb-4'>Simmiliar Jobs</h4>
                                            {
                                                findJobs.map((obj) => {
                                                    return <div className="mb-4">
                                                        <CardJobs data={obj} />
                                                    </div>
                                                })
                                            }

                                        </div>
                                        <div className="order-1 order-md-12 col-md-6 mb-4 mb-md-0">
                                            <CardJobsDetail />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </Fragment>
    )
}

export default FindJobsDetail