import React, { Component, useState } from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const Verify = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden position-relative w-100">
                <div className="wrapper__form-auth min-height-100">
                    <div className="content__center d-flex align-items-center justify-content-center">

                        <div className="row w-100 justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                <div className="text-center mb-3">
                                    <div
                                        class="navbar-brand medium font__size--20 text__20-1024 medium color__black"
                                    >
                                        <div className="d-flex align-items-center">
                                            <img src="./../images/adsad.png" alt="" />

                                            <span className="ml-2">Ngul!</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <h5 className='medium font__size--32 text__32-1024'>Verifying Your Account</h5>
                                    <p className='medium font__size--16 text__16-1024 color__gray-1'>We have just sent you 4 digit code via your email <br className='d-sm-block d-none' /> example@gmail.com</p>
                                </div>
                                <div className="wrapper__input-code d-flex justify-content-center justify-content-sm-between align-items-center px-sm-3 px-md-5 mb-4">
                                    <div className="form-group mb-4 field__input verification">
                                        <input
                                            required
                                            type="text"
                                            className="form-control medium font__size--32 text__32-1024 text__32-1024 text__32-xs medium color__black text-center"
                                            maxlength="1"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        />
                                    </div>
                                    <div className="form-group mb-4 field__input verification">
                                        <input
                                            required
                                            type="text"
                                            className="form-control medium font__size--32 text__32-1024 text__32-1024 text__32-xs medium color__black text-center"
                                            maxlength="1"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        />
                                    </div>
                                    <div className="form-group mb-4 field__input verification">
                                        <input
                                            required
                                            type="text"
                                            className="form-control medium font__size--32 text__32-1024 text__32-1024 text__32-xs medium color__black text-center"
                                            maxlength="1"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        />
                                    </div>
                                    <div className="form-group mb-4 field__input verification">
                                        <input
                                            required
                                            type="text"
                                            className="form-control medium font__size--32 text__32-1024 text__32-1024 text__32-xs medium color__black text-center"
                                            maxlength="1"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        />
                                    </div>
                                    <div className="form-group mb-4 field__input verification">
                                        <input
                                            required
                                            type="text"
                                            className="form-control medium font__size--32 text__32-1024 text__32-1024 text__32-xs medium color__black text-center"
                                            maxlength="1"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        />
                                    </div>
                                </div>
                                <NavLink to="/find-jobs" className='medium text-center font__size--16 text__16-1024 btn__auth w-100 rounded__50 mb-4'>Next</NavLink>
                                <div className="text-center">
                                    <a href="#!" className='medium font__size--16 text__16-1024 color__blue'>Resend</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Verify