import React, { useState } from 'react'
import { Fragment } from 'react';
import { useRef } from 'react';
import Slider from "react-slick";
import { FileUploader } from "react-drag-drop-files";
import { useEffect } from 'react';

const SliderForm = (props) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        swipe: false,
        beforeChange: (current, next) => {
            props.setActive(next)
        }
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };

    const fileTypes = ["JPG", "PNG", "GIF"];

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };


    return (
        <Fragment>
            <Slider ref={slider1} {...settings} className="wrapper__sliderform">
                <div className="items">
                    <h5 className='semi-bold font__size--18 text__18-1024 color__blue mb-4'>Job information</h5>
                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Job Title<sup className='color__red'>*</sup></label>
                        <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Job Title" />
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Job Category<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Job Category" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Job Type<sup className='color__red'>*</sup></label>
                                <select class="form-control wrapper__form-input medium font__size--16 text__16-1024" name="" id="">
                                    <option value="">Fulltime</option>
                                    <option value="">Parttime</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Job Sallary<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Job Sallary" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Experience<sup className='color__red'>*</sup></label>
                                <select class="form-control wrapper__form-input medium font__size--16 text__16-1024" name="" id="">
                                    <option value="">1-3 Years</option>
                                    <option value="">3-6 Years</option>
                                    <option value="">6-10 Years</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="wrapper__checkbox position-relative d-flex">
                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            <label className="circle mt-2" for="vehicle1">
                                <img src="./../images/check.png" className="check" alt="" />
                            </label>
                            <div className='ml-2 lh-1'>
                                <label className="medium font__size--14 text__14-1024 text__14-1024 mb-0" for="vehicle1">Show Salary?</label>
                                <p className='normal font__size--12 color__gray-2 mb-0'>If you prefer, the job opening salary can also be kept private.</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Job Description<sup className='color__red'>*</sup></label>
                        <textarea class="form-control wrapper__form-input textarea medium font__size--16 text__16-1024" name="" id="" cols="30" placeholder='Job Description' rows="6"></textarea>
                    </div>

                </div>
                <div className="items">
                    <h5 className='semi-bold font__size--18 text__18-1024 color__blue mb-4'>Company Information</h5>

                    <div className="wrapper__drop-wrap position-relative mb-3">
                        <div className="center text-center">

                            <img src={file ? URL.createObjectURL(file) : "./../images/gallery-add.png"} className='mb-3 mx-auto image__center' alt="" />
                            <h5 className='medium font__size--18 text__18-1024 mb-0'>{file ? file?.name : "Upload Company Logo"}</h5>
                            <p className='normal font__size--14 text__14-1024 color__gray-2 mb-0'>or drag and drop it here</p>
                        </div>
                        <div className="position-relative z-2">
                            <FileUploader handleChange={handleChange} multiple={false} label={""} hoverTitle={""} classes="wrapper__drop-item" name="file" types={fileTypes} />
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Or Upload from URL</label>
                        <div className="d-flex align-items-center">
                            <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="https://company/Image" />
                            <button className='medium font__size--16 text__16-1024 btn btn__outlined--blue color__blue no__opacity ml-3 rounded__50 btn__upload'>Upload</button>
                        </div>
                        <label for="email" className='normal color__gray-2 font__size--14 text__14-1024'><sup className='color__red'>*</sup> Company Logo should be a PNG or JPG file of 250 x 250 pixels.</label>
                    </div>

                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Company Name<sup className='color__red'>*</sup></label>
                        <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Company" />
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Company Industry<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Company Industry" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Company Website<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Company Website" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>LinkedIn<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="LinkedIn" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Instagram<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Instagram" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Twitter<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Twitter" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Facebook<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Facebook" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <h5 className='semi-bold font__size--18 text__18-1024 color__blue mb-4'>Recruiter Information</h5>

                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Full Name<sup className='color__red'>*</sup></label>
                        <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Full Name" />
                    </div>
                    <div class="form-group">
                        <label for="email" className='medium font__size--14 text__14-1024'>Email<sup className='color__red'>*</sup></label>
                        <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Email" />
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>LinkedIn<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="LinkedIn" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Instagram<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Instagram" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Twitter<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Twitter" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="email" className='medium font__size--14 text__14-1024'>Facebook<sup className='color__red'>*</sup></label>
                                <input type="email" class="form-control wrapper__form-input medium font__size--16 text__16-1024" id="email" aria-describedby="emailHelp" placeholder="Facebook" />
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>

            <div className="d-flex align-items-center justify-content-end mt-5">
                {
                    props.Active != 0 ? <div className='pointer medium font__size--16 text__16-1024 color__blue' onClick={next}>Back</div> : ""
                }
                {
                    props.Active != 2 ? <div className='pointer medium font__size--16 text__16-1024 color__white btn btn__blue rounded__50 ml-4' onClick={previous}>Next</div> : <div className='pointer medium font__size--16 text__16-1024 color__white btn btn__blue rounded__50 ml-4' onClick={() => props.setToogleModal(!props.toogleModal)}>Next</div>
                }

            </div>
        </Fragment>
    )
}

export default SliderForm