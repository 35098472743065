import React from 'react'
import { NavLink } from 'react-router-dom'
import CardJobs from '../../component/Card/CardJobs'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const SaveJobs = () => {
    const findJobs = [
        {
            logo: "./../images/ds (1).png",
            position: "Sr. UI/UX Web Designer",
            company: "Figma",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/google.png",
            position: "Sr. UI/UX Designer",
            company: "Google",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "410K",
            time: "20 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/medium.png",
            position: "Sr. UI/UX Web Designer",
            company: "Medium",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
        {
            logo: "./../images/dribbble.png",
            position: "Sr. UI/UX Web Designer",
            company: "Dribbble",
            experience: "1-3 Year",
            place: "Remote",
            location: "San Francisco, California",
            desc: "Figma is a design platform for teams who build products together. Born on the Web, Figma helps teams create, share, test, and ship better designs from start to finish...",
            tag: ["App Design", "Web Design", "Interaction Design"],
            price: "450K",
            time: "17 hours ago",
            applicant: "120"
        },
    ]
    return (
        <div className="overflow-hidden">
            <Navbar auth={true} type="blue" />

            <section className='pt-0'>
                <div className="container">
                    <div className="wrapper__content-menu">
                        <div className="mb-4">
                            <div className="d-sm-flex align-items-center justify-content-between">
                                <div className="wrapper__search  w-100 d-flex align-items-center">
                                    <img src="./../images/add (1).png" alt="" />
                                    <input type="text" placeholder='Search for job..' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                </div>
                                <div className="wrapper__location my-3 my-sm-0 mx-sm-3 d-flex align-items-center">
                                    <img src="./../images/add (2).png" alt="" />
                                    <input type="text" placeholder='Location' className='ml-2 medium font__size--14 text__14-1024 color__gray-1 w-100' />
                                </div>
                                <NavLink to="/find-jobs/search" className='btn btn__blue btn__search color__white shadow rounded__50 flex-shrink-0'>
                                    <div className="d-flex align-items-center">
                                        <img src="./../images/search-normal (1).png" alt="" />
                                        <span className='ml-2 semi-bold font__size--16 text__16-1024'>Search</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>

                        <h5 className='semi-bold font__size--18 text__18-1024 mb-4'>Saved Jobs (4)</h5>

                        <div className="row">
                            {
                                findJobs.map((obj) => {
                                    return <div className="col-md-6 mb-4">
                                        <CardJobs type="favorite" data={obj} />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default SaveJobs