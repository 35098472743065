import React, { Fragment, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import Homepage from './homepage/Blog'
import About from './homepage/About'
import Clients from './homepage/Clients'
import Blog from './homepage/Blog'
import Detail from './homepage/Detail'
import Support from './homepage/Support'
import Faq from './homepage/Faq'
import Community from './homepage/Community'
import Terms from './homepage/Terms'
import Privacy from './homepage/Privacy'

import FindJobs from './homepage/FindJobs'
import FindJobsSearch from './homepage/FindJobsSearch'
import FindJobsDetail from './homepage/FindJobsDetail'

import PostJobs from './homepage/PostJobs'
import PostJobsForm from './homepage/PostJobsForm'

import SaveJobs from './homepage/SaveJobs'

import Login from './Auth/Login'
import Signup from './Auth/SIgnup'
import Jobs from './Auth/Jobs'
import ResetPassword from './Auth/ResetPassword'
import Verify from './Auth/Verify'
import NewPassword from './Auth/NewPassword'
import axios from 'axios'

const Index = (props) => {
  const [articles, setArticles] = useState([])

  const getArticles = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/article?populate=*&populate=articles.article1.articlebg`,
        config
      )
      if (response.status === 200 || 201) {
        const data = response?.data?.data?.attributes.articles
        setArticles(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    ;(async function () {
      await getArticles()
      // setisLoading(false)
    })()
  }, [])
  return (
    <Fragment>
      <Router forceRefresh>
        <Switch>
          <Route exact path='/'>
            <Homepage props={articles} />
          </Route>
          <Route exact path='/save-jobs'>
            <SaveJobs />
          </Route>
          <Route exact path='/post-jobs'>
            <PostJobs />
          </Route>
          <Route exact path='/post-jobs/form'>
            <PostJobsForm />
          </Route>
          <Route exact path='/find-jobs'>
            <FindJobs />
          </Route>
          <Route exact path='/find-jobs/search'>
            <FindJobsSearch />
          </Route>
          <Route exact path='/find-jobs/detail'>
            <FindJobsDetail />
          </Route>
          <Route exact path='/client'>
            <Clients />
          </Route>
          <Route exact path='/about'>
            <About />
          </Route>
          <Route exact path='/blog'>
            <Blog />
          </Route>
          <Route exact path='/blog/:id'>
            <Detail props={articles} />
          </Route>
          <Route exact path='/support'>
            <Support />
          </Route>
          <Route exact path='/faq'>
            <Faq />
          </Route>
          <Route exact path='/community'>
            <Community />
          </Route>
          <Route exact path='/terms'>
            <Terms />
          </Route>
          <Route exact path='/privacy'>
            <Privacy />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route exact path='/sign-up'>
            <Signup />
          </Route>
          <Route exact path='/select-job'>
            <Jobs />
          </Route>
          <Route exact path='/reset-password'>
            <ResetPassword />
          </Route>
          <Route exact path='/verify'>
            <Verify />
          </Route>
          <Route exact path='/new-password'>
            <NewPassword />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  )
}

export default Index
